<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg11 pt-sm-6>
      <v-layout wrap>
        <v-flex xs12>
          <v-card class="mx-auto elevation-1 pa-0 pa-sm-6" outlined>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md3
                lg3
                pa-2
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >Bill History
              </v-flex>
              <!----------------------filters--------------------------------->

              <v-flex xs12 lg3 px-2>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="fromDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From"
                      outlined
                      readonly
                      dense
                      hide-details
                      clearable
                      class="rounded-0"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    scrollable
                    @change="$refs.menu.save(fromDate)"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 lg3 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="toDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To"
                      readonly
                      outlined
                      hide-details
                      dense
                      clearable
                      class="rounded-0"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    scrollable
                    @change="$refs.menu2.save(toDate)"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 lg3 px-2>
                <!-- <v-text-field
                    v-model="keyword"
                    dense
                    label="Search Bill/ Shop"
                    outlined
                    clearable
                  ></v-text-field> -->
                <v-autocomplete
                  @change="getData()"
                  :items="shop"
                  v-model="keyword"
                  label="Search Shop"
                  outlined
                  clearable
                  hide-details
                  item-text="shopName"
                  item-value="shopName"
                  dense
                  class="my-0 py-0"
                ></v-autocomplete>
              </v-flex>

              <!------------------------------------------------------->
              <!-- <v-flex xs12 lg2>
                  <v-btn x-small width="100px" dark color="grey" class="py-4" @click="adddialogue = true">
                  <v-icon x-small 
                    >mdi-plus</v-icon
                  >
                  <span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 12px"
                    >Add</span
                  ></v-btn>
              </v-flex> -->
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 text-right>
                <span class="nsbold" style="font-size: 18px" v-if="totalprice"
                  >Total Amount : {{ formatAmount(totalprice) }}</span
                >
              </v-flex>
            </v-layout>
            <!-- <v-layout py-3>
              <v-flex
                xs1
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>Id</span>
              </v-flex>
              <v-flex
                xs5
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Type</span>
              </v-flex>
            </v-layout> -->
            <!------------------------------------------->
            <v-card elevation="0" class="rounded-xl">
              <v-layout wrap>
                <v-flex xs12 px-1>
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in stock" :key="i">
                      <v-expansion-panel-header
                        @click="getinnerData(item._id)"
                        :style="i % 2 == 0 ? 'background-color:#FFF' : ''"
                      >
                        <v-layout wrap>
                          <v-flex text-left xs1 px-1 pt-1>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </v-flex>
                          <v-flex text-left xs2 px-1>
                            <span class="nsregular" style="font-size: 18px">
                              {{ item.billNo }}
                            </span>
                          </v-flex>
                          <!-- <v-flex text-center xs2 px-1>
                            <span
                              class="nsregular"
                              style="font-size: 18px"
                              v-if="item.create_date"
                            >
                              {{ formatDate(item.create_date) }}
                            </span>
                          </v-flex> -->
                          <v-flex text-left xs3 px-1>
                            <span class="nsregular" style="font-size: 18px"
                              >{{ item.shopName.shopName }}
                            </span>
                          </v-flex>
                          <v-flex text-right xs2 px-1>
                            <span
                              class="nsregular"
                              style="font-size: 18px"
                              v-if="item.purchaseDate"
                            >
                              {{ formatDate(item.purchaseDate) }}
                            </span>
                          </v-flex>
                          <v-flex text-right xs3 px-1>
                            <span
                              class="nsregular"
                              style="font-size: 18px"
                              v-if="item.amountPaid"
                            >
                              {{ formatAmount(item.amountPaid) }}
                            </span>
                          </v-flex>

                          <v-flex text-right xs1 px-1>
                            <v-icon
                              style="cursor: pointer"
                              @click="(deldialoge = true), (curId1 = item._id)"
                              >mdi-delete-outline</v-icon
                            >
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-layout wrap>
                          <v-flex xs12 lg6>
                            <v-layout wrap v-if="shopDetails">
                              <v-flex
                                xs12
                                v-if="shopDetails.billNo"
                                text-left
                                class="nsregular"
                                style="font-size: 18px"
                                pb-2
                              >
                                <span>
                                  Total Amount (Rs.):
                                  {{ shopDetails.totalAmount.toFixed(2) }}
                                </span>
                              </v-flex>
                              <v-flex
                                xs12
                                v-if="shopDetails.billNo"
                                text-left
                                class="nsregular"
                                style="font-size: 18px"
                                pb-2
                              >
                                <span>
                                  Discount:
                                  <span v-if="shopDetails.discount">
                                    {{ shopDetails.discount }}
                                  </span>
                                  <span v-else>0</span>
                                </span>
                              </v-flex>
                              <!-- <v-flex
                                xs12
                                v-if="shopDetails.amountPaid"
                                text-left
                                class="nsregular"
                                style="font-size: 18px"
                                pb-2
                              >
                                <span>
                                  Total Amount with Discount : RS
                                  {{ shopDetails.amountPaid.toFixed(2) }}
                                </span>
                              </v-flex> -->

                              <v-flex
                                xs12
                                v-if="shopDetails.amountPaid"
                                text-left
                                class="nsregular"
                                style="font-size: 18px"
                                pb-2
                              >
                                <span>
                                  Total Amount Paid to Retailer (Rs.) :
                                  {{ shopDetails.amountPaid.toFixed(2) }}
                                </span>
                              </v-flex>
                              <v-flex xs12 v-if="shopDetails.photos.length > 0">
                                <v-btn
                                  color="#766BC0"
                                  block
                                  dark
                                  dense
                                  @click="showImage(shopDetails.photos)"
                                >
                                  view bill
                                </v-btn>
                              </v-flex>
                              <!-- <v-flex xs12 v-if="shopDetails.billNo" text-left class="nsregular" style="font-size: 18px" pb-2>
                                    <span>
                                        Shop Name:{{shopDetails.shopName}}
                                    </span>
                                </v-flex> -->
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 lg6 v-if="purchaseItem">
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">No.</th>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Qty.</th>
                                    <th class="text-left">Price(Rs)</th>
                                    <th class="text-left">Total(Rs)</th>
                                    <th class="text-left">GST %</th>
                                    <th class="text-left">Edit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(value, i) in purchaseItem"
                                    :key="i"
                                  >
                                    <td>
                                      <span v-if="page == 1">
                                        {{ i + 1 }}
                                      </span>
                                      <span v-else>
                                        {{ i + 1 + 20 * (page - 1) }}
                                      </span>
                                      <!-- <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span> -->
                                    </td>
                                    <td>{{ value.itemName.itemName }}</td>
                                    <td>
                                      {{ value.quantity }}({{
                                        value.itemName.quantityType
                                      }})
                                    </td>

                                    <td>{{ value.price }}</td>

                                    <td>
                                      <span>
                                        {{
                                          value.finalAmountwithGST.toFixed(2)
                                        }}
                                      </span>
                                    </td>
                                    <td>{{ value.gstPercentage }}</td>

                                    <td>
                                      <v-icon
                                        small
                                        @click="
                                          (curItem2 = value),
                                            (editdialogue2 = true)
                                        "
                                        >mdi-pen</v-icon
                                      >
                                    </td>
                                    <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
                <v-flex xs12 pt-4>
                  <v-pagination
                    color="#766BC0"
                    small
                    v-model="page"
                    :length="Pagelength"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap justify-center>
              <v-flex xs12 v-if="mylist" px-6 pt-2>
                <v-card round style="background-color:#EEEEEE">
                </v-card>

           
                <v-card v-for="(item, i) in pending" :key="i" elevation="0">
    
                  <v-layout wrap pa-2 class="nsregular" style="font-size:16px;">
                    <v-flex xs12 pa-6 v-for="(sub,s) in item.data" :key="s">
                      {{ sub.INdate }}<br/>{{sub.clothid.QRcode}}<br/>{{sub.clothid.clothType.name}}
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                </v-card>
              </v-flex>
            </v-layout> -->
            </v-card>

            <!--------------------------------------------------->
            <!-- <v-layout
              v-for="(item, i) in stock"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs1
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ i + 1 }}</span>
              </v-flex>
              <v-flex
                xs2
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.itemName.itemName }}</span>
              </v-flex>
              <v-flex
                xs2
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ formatDate(item.quantity) }}</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.itemName.update_date }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout> -->
          </v-card>
        </v-flex>
      </v-layout>

      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="grey" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="500px" v-model="editdialogue">
        <v-card width="500px">
          <v-toolbar dark color="grey" dense flat class="body-2">
            <v-toolbar-title> Edit Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap v-if="curItem">
            <v-flex xs12 px-4 py-2>
              <v-text-field
                v-model="curItem.itemName"
                outlined
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
              <v-text-field
                v-model="curItem.itemType"
                outlined
                dense
                label="Item Type"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="editdialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="editCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="imageDialog" width="700px" height="500px">
        <v-card width="700px" height="500px">
          <v-layout wrap>
            <v-flex xs12 pa-1 v-for="(item, i) in billImage" :key="i">
              <v-img height="500px" contain :src="mediaURL + item">
                <v-layout wrap
                  ><v-flex pa-2>
                    <span>
                      <v-btn color="#766BC0" @click="onDownloadClick(item)"
                        ><v-icon>mdi-download</v-icon>
                      </v-btn>
                    </span>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog width="400px" v-model="deldialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4"
            >Are you sure you want to delete this bill?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deldialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteBill()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-dialog width="400px" v-model="adddialogue">
        <v-card width="400px">
          <v-toolbar dark color="grey" dense flat class="body-2">
            <v-toolbar-title> Add Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap>
            <v-flex xs12 px-4 py-2>
              <v-text-field autofocus v-model="name" outlined dense></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
             
            <v-select
              :items="typeList"
              v-model="typee"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-value="_id"
              outlined
              @input="search()"
              dense
            ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
            <v-select
              :items="quantitytypes"
              v-model="quantitytype"
              clearable
              label="Select Type"
              outlined
              dense
            ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2 v-if="quantitytype=='Weight'">
            <v-select
              :items="weightType"
              v-model="productType"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-id="_id"
              outlined
              dense
            ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2 v-if="quantitytype=='Liters'">
            <v-select
              :items="litersType"
              v-model="productType"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-id="_id"
              outlined
              dense
            ></v-select>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="adddialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="addCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <v-dialog width="400px" v-model="editdialogue2">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Edit Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap v-if="curItem2">
            <v-flex xs12 px-4 pb-2 pt-10 v-if="curItem2.itemName">
              <v-autocomplete
                :items="kinfOfFood"
                v-model="curItem2.itemName"
                label="select Item"
                outlined
                item-text="itemName"
                item-value="_id"
                dense
                class="my-0 py-0"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
              <v-text-field
                v-model="curItem2.quantity"
                outlined
                type="number"
                label="Qty"
                dense
                :rules="[(v) => !!v || 'This field is required']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
              <v-text-field
                v-model="curItem2.price"
                outlined
                type="number"
                label="Price"
                dense
                :rules="[(v) => !!v || 'This field is required']"
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 px-4 pb-2 v-if="curItem2.quantityType == 'Liters'">
                <v-text-field
                  v-model="curItem2.price"
                  outlined type="number"
                  label="Price"
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-flex> -->

            <v-flex xs12 px-4 pb-2>
              <v-select
                v-model="curItem2.gsttype"
                :items="itemArray"
                @change="gstcheck()"
                item-text="gst"
                label="GST type"
                dense
                outlined
                :rules="[(v) => !!v || 'This field is required']"
              ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
              <v-text-field
                v-model="curItem2.gstPercentage"
                outlined
                type="number"
                :disabled="curItem2.gsttype == 'NON GST' ? true : false"
                label="GST %"
                dense
                :rules="[(v) => !!v || 'This field is required']"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="editdialogue2 = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="editCategory2()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      imageDialog: false,
      deldialoge: false,
      curId1: null,
      billImage: [],
      test: 0,
      student: [],
      stock: [],
      gsttype: null,
      shop: [],
      menu2: null,
      menu: null,
      keyword: null,
      itemArray: [
        { gst: "SGST/CGST", abbr: "SY" },
        { gst: "IGST", abbr: "NY" },
        { gst: "NON GST", abbr: "NY" },
      ],
      shopDetails: null,
      dialoge: false,
      editdialogue: false,
      editdialogue2: false,
      adddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      curItem2: {},
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      typeList: null,
      typee: "",
      page: 1,
      Pagelength: null,
      purchasedate: null,
      // Pagelength: 0,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      quantitytypes: ["Count", "Weight", "Liters"],
      quantitytype: null,
      productType: "",
      totalprice: "",
      purchaseItem: null,
      weightType: ["Gram", "Kilo gram"],
      litersType: ["Milli Liter", "Liter"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  watch: {
    page() {
      this.getData();
    },
    fromDate() {
      // this.getData();
      // this.page = 1;
      this.getData();
    },
    toDate() {
      // this.getData();
      // this.page = 1;
      this.getData();
    },

    keyword() {
      // this.page = 1;
      // this.getData();
      this.getData();
    },
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    this.getData();
    this.getShop();
    this.getFoodType();

    // this.getinnerData();

    // this.getType()
  },
  methods: {
    showImage(item) {
      this.billImage = item;
      this.imageDialog = true;
    },
    onDownloadClick(item) {
      axios({
        method: "POST",
        url: "/purchase/getalllist",
        data: {
          id: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      console.log(item);
      var fileUrl = "https://chavara.leopardtechlabs.in/file/get/" + item;
      // console.log(fileUrl);
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.download = "image.jpeg";
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },

    getData() {
      this.appLoading = true;
      this.Pagelength = null;
      axios({
        method: "get",
        url: "/purchase/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.stock = response.data.data;
            this.totalprice = response.data.totalprice;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);

            // this.purchasedate = response.data.purchaseDetails.purchaseDate;
            // console.log( this.purchasedate,"----" )
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getShop() {
      this.appLoading = true;
      this.Pagelength = null;
      axios({
        method: "get",
        url: "/supplier/getalllist/",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.shop = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    //inner expansion
    getinnerData(id) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/item/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          purchaseId: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.purchaseItem = response.data.data;
            this.shopDetails = response.data.purchaseDetails;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
          // this.msg = response.data.msg;
          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    deleteBill() {
      axios({
        url: "/purchase/bill/delete",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          purchaseId: this.curId1,
        },
      })
        .then((response) => {
          this.delete = false;
          this.deldialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Deleted Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getFoodType() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/item/getalllist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.kinfOfFood = response.data.data;
            this.food = response.data.data.quantityType;
            this.msg = response.data.msg;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
          // this.msg = response.data.msg;
          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/item/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //call item type
    //  getType(){
    //   axios({
    //     method: "get",
    //     url: "/item/category/getalllist",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       id: this.cls
    //     }
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.typeList = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err());
    //     });
    // },

    //edit button
    editCategory() {
      axios({
        url: "/item/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          itemName: this.curItem.itemName,
          itemType: this.curItem.itemType,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    gstcheck() {
      if (this.curItem2.gsttype == "NON GST") this.curItem2.gstPercentage = 0;
    },

    editCategory2() {
      axios({
        url: "/purchase/items/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          purchaseitemId: this.curItem2._id,
          itemName: this.curItem2.itemName,
          quantity: this.curItem2.quantity,
          price: this.curItem2.price,
          gsttype: this.curItem2.gsttype,
          gstPercentage: this.curItem2.gstPercentage,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.editdialogue2 = false;
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
            this.getinnerData();
            location.reload();
            // this.router.go();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //date
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    formatAmount(item) {
      var amt = item.toFixed(2);
      return amt;
    },
  },
};
</script>